import { useEffect } from "react";
import { Button,
  Card,
  Checkbox,
  Grid,
  InputLabel,
  Typography,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio
} from "@mui/material";
import CustomDropdown from "./CustomDropdown";
import CustomTextField from "./CustomTextField";
import DateTimePicker from "./DateTimePicker";
import DurationInput from "./DurationInput";
import ShowTitleInput from './ShowTitleInput';
import { processDateLocal } from '../../utils/dateTimeHelpers';

import {
  engagementTypeOptions,
  regionOptions,
  systemOptions,
  etList,
  preRecordOptions,
  groupOptions,
  initialSchedFormState
} from "../../utils/formHelpers";
import { ContentPasteSearchOutlined } from "@mui/icons-material";

const BookingForm = ({ isBigSched, createBookingRequest, updateBookingRequest, bookingOb, setBookingOb, isEdit, setIsEdit, loading, updating }) => {
  const {
    eventTech,
    backupEventTech,
    sdskNum,
    refNum,
    name,
    region,
    system,
    date,
    hours,
    mins,
    preRecord,
    duration,
    group,
    numSpeakers,
    liveU,
    isMultiCam,
    requiredUnits,
    fieldUnitSerialNums,
    howsItViewed,
    specialRequests,
    breakouts,
    zoomWeb,
    addSpeakerRooms,
    graphSupport,
    addStreaming,
    multiday,
    laborRequest,
    invoicingContacts,
    engagementType,
    checked,
  } = bookingOb;

  const buildParams = () => {
    const fixedDate = processDateLocal(date, hours, mins);
    setBookingOb({ ...bookingOb, fixedDate: fixedDate.toISOString() })
    // this is where you do the group 3 thing
    // if no group 3 set to defaults TODO: 
    const stub = {
      eventTech,
      backupEventTech,
      sdskNum,
      refNum,
      name,
      region,
      system,
      date,
      hours,
      mins,
      preRecord,
      duration,
      group,
      numSpeakers,
      liveU,
      isMultiCam,
      requiredUnits,
      fieldUnitSerialNums,
      howsItViewed,
      specialRequests,
      breakouts,
      zoomWeb,
      addSpeakerRooms,
      graphSupport,
      addStreaming,
      multiday,
      laborRequest,
      invoicingContacts,
      engagementType,
      startTime: fixedDate.toISOString(),
      setToLiveEvent: checked,
    };
    return stub;
  };

  const handleCancel = () => {
    setIsEdit(false);
    setBookingOb(initialSchedFormState);
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    const params = buildParams();
    updateBookingRequest(params, "/edit-booking-v2");
    setIsEdit(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = buildParams();
    createBookingRequest(params, "/create-booking-v2");
  };

  const submitLabel = () => {
    if (loading) return "submitting..."
    if (updating) return "updating..."
    if (!isFormValid()) return "missing required fields"
    else return "submit"
  }

  const updateLabel = () => {
    if (updating) return "updating..."
    if (!isFormValid()) return "missing required fields"
    else return "update"
  }

  // cleans up state before unmounting
  useEffect(() =>{
    return () => {
      setBookingOb(initialSchedFormState);
      setIsEdit(false);
    }
  }, [setBookingOb, setIsEdit])

  const isFormValid = () => {
    const fixedDate = processDateLocal(date, hours, mins);
    const newby = new Date()

    let refNumFormat = new RegExp('^[0-9]{6}-[0-9]{2}$'); // 6 digits dash 2 digits.  why doesn't \d work?? 
    let SDSKNumFormat = new RegExp('^[0-9]{10}-[0-9]{2}$'); // 10 digits dash 2 digits.
    if (
      (!refNum || refNumFormat.test(refNum)) &&
      engagementType !== '' &&
      engagementType !== null &&
      (engagementType === 'Test' || SDSKNumFormat.test(sdskNum)) &&
      system !== '' &&
      region !== '' &&
      name.length > 2 &&
      date !== null &&
      hours !== "" &&
      mins !== "" &&
      duration > 1 &&
      group &&
      numSpeakers >= 0 &&
      fixedDate > newby
    ) {
      return true;
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{ height: isBigSched ? 1200 : 600, overflow: "auto", borderColor: isEdit? 'red' : 'green' }}
    >
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            {"Submit a booking"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomDropdown
            value={eventTech}
            setValue={(value) => setBookingOb({ ...bookingOb, eventTech: value })}
            label="Event Technician"
            options={etList}
            isRequired
          />
        </Grid>
        <Grid item xs={6}>
          <CustomDropdown
            value={backupEventTech}
            setValue={(value) => setBookingOb({ ...bookingOb, backupEventTech: value })}
            label="Backup Technician"
            options={etList}
          />
        </Grid>
        <Grid container justifyContent='flex-end' sx={{pt: 1, pr: 2}}>
          <InputLabel>please add 4 digit year to the front of the BGET number</InputLabel>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            setValue={(value) =>
              setBookingOb({ ...bookingOb, sdskNum: value })
            }
            value={sdskNum}
            isDisabled={isEdit}
            label="BGET Number"
            isRequired
          />
        </Grid>
        {/* <Grid item xs={6}>
          <CustomTextField
            setValue={(value) =>
              setBookingOb({ ...bookingOb, refNum: value })
            }
            value={refNum}
            isDisabled={isEdit}
            label="BGET Number"
          />
        </Grid> */}
        <Grid item xs={12}>
          <ShowTitleInput
            setName={(value) => setBookingOb({ ...bookingOb, name: value })}
            setNameValue={name}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomDropdown
            value={region}
            setValue={(value) => setBookingOb({ ...bookingOb, region: value })}
            options={regionOptions}
            label="Region"
            isRequired
          />
        </Grid>
        <Grid item xs={6}>
          <CustomDropdown
            value={system}
            setValue={(value) => setBookingOb({ ...bookingOb, system: value })}
            options={systemOptions}
            label="System"
            isRequired
            isDisabled={isEdit}
          />
        </Grid>
        <Grid container justifyContent='flex-end' sx={{pt: 1, pr: 5}}>
          <InputLabel>Event Start Time - Your Time Zone (use 24 hour clock)</InputLabel>
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            date={date}
            setDate={(value) => setBookingOb({ ...bookingOb, date: value })}
            hours={hours}
            setHour={(value) => setBookingOb({ ...bookingOb, hours: value })}
            mins={mins}
            setMin={(value) => setBookingOb({ ...bookingOb, mins: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>*Pre Production (Hours)</InputLabel>
          {/* refactor to use this here and in duration */}
          <ToggleButtonGroup
            value={preRecord}
            exclusive
            onChange={(e, value) => setBookingOb({ ...bookingOb, preRecord: value })}
          >
            {preRecordOptions.map((num) => (
              <ToggleButton size="small" key={num} value={num}>
                {num}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={8}>
          <InputLabel>*Event Duration (Hours)</InputLabel>
          <DurationInput
            duration={duration}
            setDuration={(value) =>
              setBookingOb({ ...bookingOb, duration: value })
            }
            group3={group===3}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomDropdown
            value={group}
            setValue={(value) => setBookingOb({ ...bookingOb, group: value })}
            options={groupOptions}
            label="Group"
            isRequired
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            setValue={(value) =>
              setBookingOb({ ...bookingOb, numSpeakers: value })
            }
            value={numSpeakers}
            disableMode={isEdit}
            label="Number of Speakers"
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="LiveU Required"
            control={
              <Checkbox
                disabled={group!==3}
                checked={liveU}
                onChange={(event) =>
                  setBookingOb({ ...bookingOb, liveU: event.target.checked })
                }
                value={liveU}
              />
            }
          />
          {(liveU && group===3) && (
            <Grid>
              <Grid item sx={{mb:1, ml:4}}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={false}
                    name="radio-buttons-group"
                    onChange={(e) => setBookingOb({ ...bookingOb, isMultiCam: Boolean(e.target.value)})}
                    value={isMultiCam}
                  >
                    <FormControlLabel value={false} control={<Radio />} label="Primary and Backup" />
                    <FormControlLabel value={true} control={<Radio />} label="Multi Cam" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item sx={{mb:1, ml:4}}>
                <TextField
                  fullWidth
                  label={"How many units are required"}
                  onChange={(e) => setBookingOb({ ...bookingOb, requiredUnits: e.target.value})}
                  value={requiredUnits}
                />
              </Grid>
              <Grid item sx={{mb:1, ml:4}}>
                <TextField
                  fullWidth
                  label={"Serial numbers of field units"}
                  onChange={(e) => setBookingOb({ ...bookingOb, fieldUnitSerialNums: e.target.value})}
                  value={fieldUnitSerialNums}
                />
              </Grid>
              <Grid item sx={{mb:1, ml:4}}>
                <TextField
                  fullWidth
                  label={"How the ET wishes to view the units (Number of rooms and locations)"}
                  onChange={(e) => setBookingOb({ ...bookingOb, howsItViewed: e.target.value})}
                  value={howsItViewed}
                />
              </Grid>
            </Grid>)
          }
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Special Requests</InputLabel>
          <Grid sx={{ml:4}}>
            <Grid item>
              <FormControlLabel
                label="Breakouts"
                control={
                  <Checkbox
                    disabled={group!==3}
                    checked={breakouts}
                    onChange={(event) =>
                      setBookingOb({ ...bookingOb, breakouts: event.target.checked })
                    }
                    value={breakouts}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Zoom Webinar"
                control={
                  <Checkbox
                    disabled={group!==3}
                    checked={zoomWeb}
                    onChange={(event) =>
                      setBookingOb({ ...bookingOb, zoomWeb: event.target.checked })
                    }
                    value={zoomWeb}
                  />
                }
              />
            </Grid><Grid item>
              <FormControlLabel
                label="Additional Speaker Rooms"
                control={
                  <Checkbox
                    disabled={group!==3}
                    checked={addSpeakerRooms}
                    onChange={(event) =>
                      setBookingOb({ ...bookingOb, addSpeakerRooms: event.target.checked })
                    }
                    value={addSpeakerRooms}
                  />
                }
              />
            </Grid><Grid item>
              <FormControlLabel
                label="Additional Graphics Support"
                control={
                  <Checkbox
                    disabled={group!==3}
                    checked={graphSupport}
                    onChange={(event) =>
                      setBookingOb({ ...bookingOb, graphSupport: event.target.checked })
                    }
                    value={graphSupport}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Additional streaming desintations (HLS, Live<go>, other)"
                control={
                  <Checkbox
                    disabled={group!==3}
                    checked={addStreaming}
                    onChange={(event) =>
                      setBookingOb({ ...bookingOb, addStreaming: event.target.checked })
                    }
                    value={addStreaming}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Multiday Event"
                control={
                  <Checkbox
                    disabled={group!==3}
                    checked={multiday}
                    onChange={(event) =>
                      setBookingOb({ ...bookingOb, multiday: event.target.checked })
                    }
                    value={multiday}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={"Any Special Requests of Labor"}
            onChange={(e) => setBookingOb({ ...bookingOb, laborRequest: e.target.value})}
            value={laborRequest}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={"Invoicing Contacts (if additional invoicing is required)"}
            onChange={(e) => setBookingOb({ ...bookingOb, invoicingContacts: e.target.value})}
            value={invoicingContacts}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomDropdown
            value={engagementType}
            setValue={(value) => setBookingOb({ ...bookingOb, engagementType: value })}
            label="Engagement Type"
            isRequired
            options={engagementTypeOptions}
            isDisabled={isEdit}
          />
        </Grid>
        {!isEdit ? (
          <Grid item xs={12}>
            <Tooltip title="All fields with an asterisk (*) are required">
              <span>
                <Button
                  fullWidth
                  type="submit"
                  color={isFormValid() ? 'primary' : 'warning'}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!isFormValid() || loading || updating}
                >
                  {/* {isFormValid() ? 'Submit' : 'Missing Required Fields'} */}
                  {submitLabel()}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={handleCancel}
                >
                  cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  color={isFormValid() ? 'primary' : 'warning'}
                  variant="contained"
                  onClick={handleUpdate}
                >
                  {updateLabel()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export default BookingForm;