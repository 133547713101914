import { useState } from "react";
import StudioPage from "./Studio";
import PropagatorStudioSelectPage from "./PropagatorStudioSelect";

const StudioHomePage = () => {
    const [studio, setStudio] = useState(null);
    return(
      <main>
        {studio ? (
          <StudioPage
            studio={studio}
            size="large"
            goBack={() => setStudio(null)}
         />
        ) : (
          <PropagatorStudioSelectPage setStudio={setStudio} />
        )}
      </main>)
}

export default StudioHomePage