import { useEffect } from "react";

import { Box } from "@mui/material";
import { extendedSourceLabels, sourceLabels } from "../../utils/formHelpers";
import DestinationController from "./DestinationController";
// import HLSPageLink from "./HLSPageLink";
import { useFetchData } from '../../hooks/useFetchData';
import { useInterval } from "../../hooks/useInterval";
import HLSController from "./HLSController";

export const StudioBody = ({
  studio,
  setChannelsExist,
  setIsStudioEmpty,
  setIsStudioRunning
}) => {
  const [{data: channelStatusResponse}, channelStatusRequest] = useFetchData();
  useEffect(
    () => channelStatusRequest({ studio }, "/channel-status-v2"),
    [studio, channelStatusRequest]
  );
  useEffect(
    () => {
      const channelStatusValue = channelStatusResponse?.message;
      if (channelStatusValue && Object.keys(channelStatusValue).length === 0) setIsStudioEmpty(true)
      if (channelStatusValue && Object.keys(channelStatusValue).length > 0) setIsStudioEmpty(false)
      if (channelStatusValue && Object.values(channelStatusValue).find((chan) => {
        return chan.status.toUpperCase() === 'RUNNING'
      })) setIsStudioRunning(true)
      if (channelStatusValue && !Object.values(channelStatusValue).find((chan) => {
        return chan.status.toUpperCase() === 'RUNNING'
      })) setIsStudioRunning(false)
      return channelStatusResponse?.message && setChannelsExist(Object.keys(channelStatusResponse.message).length > 0)
    },
    [channelStatusResponse, setChannelsExist, setIsStudioEmpty, setIsStudioRunning]
  );
  useInterval(async () => {
    channelStatusResponse?.message &&
    await channelStatusRequest({ studio }, "/channel-status-v2");
  }, 10000);
  return (
    <>
    {channelStatusResponse ? <>
      {/* <HLSPageLink studio={studio} /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: 'flex-start',
          flexDirection: "row",
          flexWrap: "wrap",
        }}
        textAlign="center"
      >
        {sourceLabels.concat(extendedSourceLabels).map((sourceOb) => (
          <DestinationController 
            key={sourceOb.source} 
            sourceOb={sourceOb} 
            studio={studio} 
            data={channelStatusResponse?.message[sourceOb.source]}
          />
        ))}
        <HLSController studio={studio} data={channelStatusResponse?.message.livego} />
      </Box>
    </>: null}
    </>
  );
};

export default StudioBody;
