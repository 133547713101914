import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import theme from "./theme";
// import reportWebVitals from './reportWebVitals';
import Amplify, { Auth } from "aws-amplify";
import { Authenticator, Heading } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css"; // default theme
import HLSPlayer from "./pages/HLSPlayer";
import { Typography } from "@mui/material";
const REGION = process.env.REACT_APP_AWS_PROJECT_REGION;
const USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID;
const CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID;
const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: CLIENT_ID,
    authenticationFlowType: "CUSTOM_AUTH",
  },
  API: {
    endpoints: [
      {
        name: "default",
        endpoint: GRAPHQL_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

const services = {
  async handleSignIn(formData) {
    let { username, password } = formData;
    username = username.toLowerCase();
    return Auth.signIn(username, password)
      .then((user) => {
        user.challengeName = "SMS_MFA"
        return user;
      });
  },
  async handleConfirmSignIn(formData) {
    let { user, code } = formData;
    user.challengeName = "CUSTOM_CHALLENGE";
    return Auth.sendCustomChallengeAnswer(user, code)
      .then(async (user) => {
        user.authenticationFlowType = "CUSTOM_AUTH"
        return user
      })
      // .catch((err) => console.log(err));
  },
};

const formFields = {
  confirmSignIn: {
    confirmation_code: {
      labelHidden: true,
      placeholder: 'Enter mfa code from email',
      isRequired: true,
    },
  },
};

const components = {
  ConfirmSignIn: {
    Header() {
      return (
        <Heading
          padding={2}
          level={3}
        >
          Email MFA Verification
        </Heading>
      );
    },
  },
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <Authenticator services={services} formFields={formFields} components={components}>
          {({ signOut, user }) => {
          return( 
            <App signOut={signOut} user={user} />
            )
          }}
        </Authenticator>
        }
      />
      <Route path="/playerStudio1" element={<HLSPlayer link="https://d170b5z793pntc.cloudfront.net/out/v1/44ecd62c55d147bfb45ee05e00542b1d/index.m3u8" />} />
      <Route path="/playerStudio2" element={<HLSPlayer link="https://d1lelmgvaah3hk.cloudfront.net/out/v1/7d65cda725ff41ffb94492d6583d76bb/index.m3u8" />} />
      <Route path="/playerStudio3" element={<HLSPlayer link="https://diar76dnootuv.cloudfront.net/out/v1/7915845da010413289ad4030b350d08b/index.m3u8" />} />
      <Route path="/playerStudio4" element={<HLSPlayer link="https://d2jjay1pizzmew.cloudfront.net/out/v1/4c19601a27ad4f75af554e2538f23cdd/index.m3u8" />} />
      <Route path="/livegoHLSplayer" element={<HLSPlayer link="https://d3k03ebfhxzoph.cloudfront.net/out/v1/076226963150462384414e6c24835a10/index.m3u8" />} />
      <Route path="/breakout1" element={<HLSPlayer link="https://d1i8xxg0zbu6ko.cloudfront.net/out/v1/1695c263a2284b5ab58220cc00137731/index.m3u8" />} />
      <Route path="/breakout2" element={<HLSPlayer link="https://d1rns2wg8voxy6.cloudfront.net/out/v1/a18dcd6cdfab4639acca8f913f04888b/index.m3u8" />} />
      <Route path="/breakout3" element={<HLSPlayer link="https://d35p4vwfccjz5v.cloudfront.net/out/v1/2b9cbc1186b64cd7ab02b5ab6e0a9665/index.m3u8" />} />
      <Route path="*" element={<Navigate to ="/" />}/>

    
    </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
// // https://bookings.bbg.originalsyndicate.com/playerStudio1
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
