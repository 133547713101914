import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TimeZoneMap from "../../static/fancyTZmap.png";

const DateTimePicker = ({ date, setDate, hours, setHour, mins, setMin }) => {
  const handleHourChange = (e) => {
    setHour(e.target.value);
  };

  const handleMinChange = (e) => {
    setMin(e.target.value);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const now = new Date();
  const maxDate = now.setMonth(now.getMonth() + 3);
  const timeNums = new Array(24).fill("", 0, 24);;
  const minNums = ["00", "15", "30", "45"];
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={5}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              renderInput={(props) => <TextField {...props} />}
              label="DatePicker"
              value={new Date(date)}
              maxDate={maxDate}
              disablePast
              onChange={(newDate) => {
                setDate(newDate);
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="hourSelect">Hour</InputLabel>
          <Select
            labelId="some other label"
            id="hourSelect"
            label="Hour"
            value={hours}
            onChange={handleHourChange}
          >
            {timeNums.map((time, i) => {
              return (
                <MenuItem key={i} value={i}>
                  {String(i).padStart(2, "0")}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="minSelect">Min</InputLabel>
          <Select
            labelId="some min label"
            id="minSelect"
            label="Min"
            value={mins}
            onChange={handleMinChange}
          >
            {minNums.map((mins) => (
              <MenuItem key={mins} value={mins}>
                {mins}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label="info"
          size="small"
          sx={{ mt: 0 }}
          onClick={handleOpen}
        >
          <InfoIcon />
        </IconButton>
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box
          component="img"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
          }}
          alt="time zone map"
          src={TimeZoneMap}
        />
      </Modal>
    </Grid>
  );
};

export default DateTimePicker;
