import { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useFetchData } from "../../hooks/useFetchData";

export const DestinationController = ({ studio, sourceOb, data }) => {
  const [status, setStatus] = useState(data?.status || null);
  useEffect(() => {
    setStatus(data?.status || null)
  }, [data])
  const [, createChannelRequest] = useFetchData();
  const [, startChannelRequest] = useFetchData();
  const [, stopChannelRequest] = useFetchData();
  const [, deleteChannelRequest] = useFetchData();
  const createHandler = (data) => {
    if (!data.backupUrl) {
      data.backupUrl = "rtmp://foo.com/xxx" + Date.now().toString(10)
    }
    if (!data.backupStreamKey) {
      data.backupStreamKey = "xxxx";
    }
    const dataToSubmit = {
      studio,
      destination: sourceOb.source,
      data,
    }
    setStatus('CREATING')
    createChannelRequest(
      dataToSubmit,
      "/create-channel"
    );
  }
  const startHandler = () => {
    const dataToSubmit = {
      studio,
      destination: sourceOb.source,
    }
    setStatus('STARTING')
    startChannelRequest(dataToSubmit, '/start-channel')
  }
  const stopHandler = () => {
    const dataToSubmit = {
      studio,
      destination: sourceOb.source,
    }
    setStatus('STOPPING')
    stopChannelRequest(dataToSubmit,'/stop-channel')
  }
  const deleteHandler = () => {
    const dataToSubmit = {
      studio,
      destination: sourceOb.source,
    }
    setStatus('DELETING');
    deleteChannelRequest(dataToSubmit,'/delete-channel');
  }
  // STUFFMFLKENFOENFJOSEFJESLFNSEFNLES
  const [priUrl, setPriUrl] = useState(data?.priUrl || null);
  const [priStreamKey, setPriStreamKey] = useState(data?.priStreamKey || null);
  const [backupUrl, setBackupUrl] = useState(data?.buUrl || null);
  const [backupStreamKey, setBackupStreamKey] = useState(data?.buStreamKey || null);
  // const clearFields = () => {
  //   setPriUrl(null);
  //   setPriStreamKey(null);
  //   setBackupUrl(null);
  //   setBackupStreamKey(null);
  // }
  const handleCreate = () => {
    const payload = {
      priUrl,
      priStreamKey,
      backupUrl,
      backupStreamKey
    }
    createHandler(payload);
  };


  return (
    <form>
      <Box
        key={sourceOb.source}
        sx={{
          display: "flex",
          ml: 1,
          mb: 4,
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{ fontWeight: 400 }}
          align="left"
          variant="h4"
          component="h4"
        >
          {sourceOb.label}
        </Typography>
        <Typography
          sx={{ fontWeight: 200 }}
          align="left"
          variant="overline"
        >
          {`Primary Pipeline`}
        </Typography>
        <TextField
          InputProps={{
            sx: { fontSize: "12px", minWidth: "240px" },
          }}
          label={`Url`}
          disabled={!!data}
          value={priUrl}
          onChange={(e) => {
            setPriUrl(e.target.value)
            }}
        />
        <TextField
          InputProps={{
            sx: { fontSize: "12px", minWidth: "240px" },
          }}
          sx={{ my: 1 }}
          label={`Stream Key`}
          disabled={!!data}
          value={priStreamKey}
          onChange={(e) => {
            setPriStreamKey(e.target.value)
            }}
        />
        <Typography
          sx={{ fontWeight: 200 }}
          align="left"
          variant="overline"
        >
          {`Backup Pipeline`}
        </Typography>
        <TextField
          InputProps={{
            sx: { fontSize: "12px", minWidth: "240px" },
          }}
          label={`Url`}
          disabled={!!data}
          value={backupUrl}
          onChange={(e) => {
            setBackupUrl(e.target.value)
            }
          }
        />
        <TextField
          sx={{ my: 1 }}
          label={`Stream Key`}
          disabled={!!data}
          value={backupStreamKey}
          onChange={(e) => {
            setBackupStreamKey(e.target.value)
            }
          }
        />
        <Typography
          sx={{ fontWeight: 200 }}
          align="left"
          variant="overline"
        >
          {status ? status : `Channel Controls`}
        </Typography>
        <Button
          sx={{
            my: 0.2,
          }}
          variant="contained"
          color="info"
          size="small"
          disabled={!!data || status === 'CREATING'}
          onClick={handleCreate}
        >Create</Button>
        <Button
          sx={{
            my: 0.2,
          }}
          variant="contained"
          color="success"
          size="small"
          disabled={status !== 'IDLE'}
          onClick={startHandler}
        >Start</Button>
        <Button
          sx={{
            my: 0.2,
          }}
          variant="contained"
          color="warning"
          size="small"
          disabled={status !== 'RUNNING' || status === null}
          onClick={stopHandler}
        >Stop</Button>
        <Button
          sx={{
            my: 0.2,
          }}
          variant="contained"
          color="error"
          size="small"
          disabled={status !== 'IDLE'}
          onClick={deleteHandler}
        >Delete</Button>
      </Box>
    </form>
  )
};

export default DestinationController;