import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function ActionAreaCard({ name, clickHandler }) {
  return (
    <Card sx={{width:200, height: 200}} onClick={clickHandler}>
      <CardActionArea sx={{height: 200, textAlign: "center"}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
