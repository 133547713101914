import { Grid } from "@mui/material";
import { useState } from "react";
import AppCard from "../components/shared/SquareCard";
import RefTestModal  from "../components/Propogator/RefTestModal";

const appList = ["Schedulator", "Propagator"];

export const AppHomePage = ({setApp}) => {
  const [modalState, setModal] = useState(false);
  const [modalText, setModalText] = useState(null);
  let refNumFormat = new RegExp('^[0-9]{10}-[0-9]{2}$');
  const checkCode = code => refNumFormat.test(code)
    ? setApp("Propagator")
    : setModalText("Invalid SDSK Number");

  const handleSelection = app => app === "Propagator"
    ? setModal(true)
    : setApp(app);

  return(
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="center"
    rowSpacing={{xs:4}}
    columnSpacing={{xs: 20, md: 50}}
  >
    {appList.map((app) => (
      <Grid item key={app}>
        <AppCard name={app} clickHandler={() => handleSelection(app)} />
        <RefTestModal 
          modalText={modalText}
          setModalText={setModalText}
          modalState={modalState}
          setModal={setModal}
          checkCode={checkCode}
        />
      </Grid>
    ))}
  </Grid>)
}

export default AppHomePage;
