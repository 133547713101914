import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
      // main: "#772233",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    mode: "dark",
  },
  typography: {
    fontFamily: [
      "Helvetica",
      "Roboto",
      "Arial",
      'sans-serif'
    ].join(',')
  }
});

export default theme;
