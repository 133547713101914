import { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from "@mui/material";
import Schedule from '../components/Schedule/Schedule';
import BookingForm from '../components/BookingForm/BookingForm';
import ConfirmationModal from '../components/shared/ConfirmationModal';

import { BookingMode } from "../utils/Context";
import { SchedulatorContext } from "../utils/Context";
import { useFetchData } from "../hooks/useFetchData";
import { initialSchedFormState } from '../utils/formHelpers';

// NOTE : aka Schedulator
const BookingsPage = () => {
  const { bookingOb, setBookingOb } = useContext(SchedulatorContext);
  const { isEdit, setIsEdit } = useContext(BookingMode);
  const { system, sdskNum, duration, fixedDate  } = bookingOb;
  const [bookingResponse, createBookingRequest] = useFetchData();
  const [updateBookingResponse, updateBookingRequest] = useFetchData();

  const [isModalOpen, setModal] = useState(false);
  const [modalBody, setModalBody] = useState("body");
  const [modalHeader, setModalHeader] = useState("header");
  const handleModalClose = () => {
    setModal(false);
    if(!bookingResponse.error){
      setBookingOb(initialSchedFormState)
    }
  }

  // Submit Booking Modal
  useEffect(() => {
    if (bookingResponse.data) {
      setModalHeader("Successfully created booking");
      setModalBody(`A booking with reference number: ${sdskNum} has sucessfully
      been created for ${fixedDate} with a duration of ${duration} hours`);
      setModal(true);
    } else if (bookingResponse.error) {
      setModalHeader(bookingResponse.error.message);
      setModalBody(bookingResponse.error.response.data.message);
      setModal(true);
    }
  }, [bookingResponse.data, bookingResponse.error]);

  //  Update Booking Modal
  useEffect(() => {
    if (updateBookingResponse.data) {
      setModalHeader("Successfully updated booking");
      setModalBody(`A booking with reference number: ${sdskNum} has sucessfully been
      updated for ${fixedDate} with a duration of ${duration} hours`);
      setModal(true);
    } else if (updateBookingResponse.error) {
      setModalHeader(updateBookingResponse.error.message);
      setModalBody(updateBookingResponse.error.response.data.message);
      setModal(true);
    }
  }, [updateBookingResponse.data, updateBookingResponse.error]);

  const [isBigSched, setIsBigSched] = useState(false)
  return (
    <>
      <Typography variant="h4" align="center">
        {system ? system.replace('_', ' ') : null}
      </Typography>
      <Button onClick={() => setIsBigSched(!isBigSched)}>{isBigSched ? 'Collapse' : 'Expand'}</Button>
      <Grid container spacing={2}>
        {isBigSched ? 
        <>
        <Grid item xs={12}>
          <BookingForm
            isBigSched={isBigSched}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            bookingOb={bookingOb}
            setBookingOb={setBookingOb}
            createBookingRequest={createBookingRequest}
            updateBookingRequest={updateBookingRequest}
            loading={bookingResponse?.isLoading || false}
            updating={updateBookingResponse?.isLoading || false}
          />
        </Grid>
        <Grid item xs={12}>
          <Schedule
            isEdit={isEdit}
            bookingResponse={bookingResponse}
            updateBookingResponse={updateBookingResponse}
            system={system}
          />
        </Grid>
        </> : 
        <>
        <Grid item xs={12} lg={4}>
          <BookingForm
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            bookingOb={bookingOb}
            setBookingOb={setBookingOb}
            createBookingRequest={createBookingRequest}
            updateBookingRequest={updateBookingRequest}
            loading={bookingResponse?.isLoading || false}
            updating={updateBookingResponse?.isLoading || false}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Schedule
            isEdit={isEdit}
            bookingResponse={bookingResponse}
            updateBookingResponse={updateBookingResponse}
            system={system}
          />
        </Grid>
        </>
        }
        <ConfirmationModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          modalBody={modalBody}
          modalHeader={modalHeader}
        />
      </Grid>
    </>
  );
};

export default BookingsPage;