import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

const DurationInput = ({ duration, setDuration, group3 }) => {
  const toggleNums = group3 ? 
  [2, 4, 4.25, 4.5, 4.75, 5, 5.5, 6, 6.5, 7, 8, 10, 12]
  :[2, 4, 4.25, 4.5, 4.75, 5, 5.5, 6, 6.5, 7];

  const handleDuration = (event, newDuration) => {
    setDuration(newDuration);
  };
  return (
    <Grid container direction={{ xs: "column", md: "row" }}>
      <Grid item>
        <ToggleButtonGroup
          value={duration}
          exclusive
          onChange={handleDuration}
        >
          {toggleNums.map((num) => (
            <ToggleButton size="small" key={num} value={num}>
              {num}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default DurationInput;
