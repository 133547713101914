import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { useFetchData } from '../../hooks/useFetchData';
import { useInterval } from "../../hooks/useInterval";

const slowPollingTime = 30000;
const fastPollingTime = 3000;

const statusMap = {
  STANDBY: 'DISARMED',
  ACTIVE: 'ARMED',
};
export const StudioStatus = ({ studio, setStudioArmed, channelsExist }) => {
  const [priStatus, setPriStatus] = useState("STANDBY");
  const [buStatus, setBuStatus] = useState("STANDBY");
  const [pollInterval, setPollInterval] = useState(slowPollingTime);
  const [, startFlowRequest] = useFetchData();
  const [, stopFlowRequest] = useFetchData();

  const [{data: studioStatusResponse}, studioStatusRequest] = useFetchData();

  // Sets the status based on response, and sets polling back to 15 seconds for armed or disarmed
  useEffect(() => {
    studioStatusResponse && studioStatusResponse?.message?.backup
      ? setBuStatus(studioStatusResponse.message.backup)
      : setBuStatus("STANDBY");
    studioStatusResponse && studioStatusResponse?.message?.primary
      ? setPriStatus(studioStatusResponse.message.primary)
      : setPriStatus("STANDBY");
    if(studioStatusResponse 
      && ((studioStatusResponse?.message?.primary === 'ACTIVE' && studioStatusResponse?.message?.backup === 'ACTIVE')
      || (studioStatusResponse?.message?.primary === 'STANDBY' && studioStatusResponse?.message?.backup === 'STANDBY'))){
        setPollInterval(slowPollingTime);
      }
    if(studioStatusResponse 
      && (studioStatusResponse?.message?.primary === 'ACTIVE' && studioStatusResponse?.message?.backup === 'ACTIVE')) {
        setStudioArmed(true);
      }
    if(studioStatusResponse 
      && (studioStatusResponse?.message?.primary === 'STANDBY' && studioStatusResponse?.message?.backup === 'STANDBY')) {
        setStudioArmed(false);
      }
  }, [studioStatusResponse, setStudioArmed]);
    // Initial studio status fetch
  useEffect(
    () => studioStatusRequest({ studio }, "/studio-status-v2"),
    [studio, studioStatusRequest]
  );
    // Polling for channel status updates
  useInterval(async () => {
    studioStatusResponse && studioStatusResponse.message &&
    await studioStatusRequest({ studio }, "/studio-status-v2");
  }, pollInterval);

  const systemArmed = statusMap[priStatus] === 'ARMED' && statusMap[buStatus] === 'ARMED';
  const systemDisarmed = statusMap[priStatus] === 'DISARMED' && statusMap[buStatus] === 'DISARMED';
  
  const handleArm = () => {
    setPriStatus('pending')
    setBuStatus('pending')
    setPollInterval(fastPollingTime)
    startFlowRequest({ studio }, "/start-flow");
  }
  const handleDisarm = () => {
    setPriStatus('pending')
    setBuStatus('pending')
    setPollInterval(fastPollingTime)
    stopFlowRequest({ studio }, "/stop-flow");
  }

 return (
  <>
    <Typography
      sx={{ fontWeight: 400 }}
      align="center"
      variant="h4"
      component="h4"
    >{`Primary Status: ${priStatus && statusMap[priStatus] ? statusMap[priStatus] : 'Pending...'}`}</Typography>
    <Typography
      sx={{ fontWeight: 400 }}
      align="center"
      variant="h4"
      component="h4"
      mb={4}
    >{`Backup Status: ${studioStatusResponse && statusMap[buStatus] ? statusMap[buStatus] : 'Pending...'}`}</Typography>
    <Grid sx={{mt: -3, mb: 4}} container justifyContent="center" alignItems="center">
      {systemArmed ?
        <Button
          disabled={!systemArmed || channelsExist}
          color='warning'
          variant='contained'
          onClick={handleDisarm}
        >Disarm System</Button>
        :
        <Button
          disabled={!systemDisarmed}
          color='info'
          variant='contained'
          onClick={handleArm}
        >Arm System</Button>
      }
    </Grid>
  </>
)};

export default StudioStatus;
