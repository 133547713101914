import { TextField } from "@mui/material";

const CustomTextField = ({ setValue, value, isDisabled, label, isRequired = false }) => {
  return (
    <TextField
      fullWidth
      label={label}
      onChange={(e) => setValue(e.target.value)}
      required={isRequired}
      value={value}
      disabled={isDisabled}
    ></TextField>
  );
};

export default CustomTextField;
