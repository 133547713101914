import { Grid } from "@mui/material";
import ReactHlsPlayer from 'react-hls-player';

export const PlayerPage = ({link}) => {

  return(
  <Grid>
    <ReactHlsPlayer
      src={link}
      autoPlay={false}
      controls={true}
      width="100%"
      height="auto"
    />
  </Grid>
  )
}

export default PlayerPage;

