import { useEffect, useState } from 'react';
import { Button, Box, Modal, Stack, Typography, ButtonGroup }from '@mui/material';
import StudioHeader from "../components/Propogator/StudioHeader";
import StudioStatus from "../components/Propogator/StudioStatus";
import StudioBody from "../components/Propogator/StudioBody";
import { extendedSourceLabels, sourceLabels } from "../utils/formHelpers";
import { studioLabels } from '../constants';
import { useFetchData } from "../hooks/useFetchData";


const StudioPage = ({ studio, goBack }) => {
  const [studioArmed, setStudioArmed] = useState(false);
  const [channelsExist, setChannelsExist] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isStudioEmpty, setIsStudioEmpty] = useState(false);
  const [isStudioRunning, setIsStudioRunning] = useState(false);
  const [isStop, setIsStop] = useState(false);
  const [, deleteGlobalRequest] = useFetchData();
  const [, stopGlobalRequest] = useFetchData();

  const confirmMsg = `Please confirm ${isStop ? "Global Delete" : "Global Stop"}`
  const [modalMsg, setModalMsg] = useState(confirmMsg)


  useEffect(() => {
    if (isStudioEmpty === true) {
      handleModalClose();
      setModalMsg(confirmMsg)
    }
  }, [isStudioEmpty])

  useEffect(() => {
    if (isStudioEmpty === false && !isStudioRunning) {
      handleModalClose();
      setModalMsg(confirmMsg)
    }
  }, [isStudioEmpty, isStudioRunning])



  const deleteGlobalHandler = () => {
    const dataToSubmit = {
      studio
    }
    setModalMsg("Deleting...")
    deleteGlobalRequest(dataToSubmit,'/delete-global');
  }

  const stopGlobalHandler = () => {
    const dataToSubmit = {
      studio
    }
    setModalMsg("Stopping...")
    stopGlobalRequest(dataToSubmit, '/stop-global');
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  useEffect(
    () => {
      console.log("studio statuses", isStudioEmpty)
    },[isStudioEmpty]
  )

  return (
    <>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
            container
            display="flex"
            justifyContent="center"
            sx={{
              backgroundColor: "#111111",
              p: 5,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Stack 
              spacing={2}
              sx={{width: 400}}
              useFlexGap
              justifyContent="center"
              alignItems="center"
            >
              <Typography color="primary.warning">
                {modalMsg}
              </Typography>
              <ButtonGroup align="center">
                <Button variant="contained" onClick={handleModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color={isStop ? "warning" : "error" }
                  onClick={isStop ? stopGlobalHandler : deleteGlobalHandler}
                >
                  {isStop ? "Stop" : "Delete"}
                </Button>
              </ButtonGroup>
            </Stack>
          </Box>
      </Modal>
      <Button
        variant="outlined"
        size="large"
        color="info"
        onClick={goBack}
      >
        Back
      </Button>
      <StudioHeader title={studioLabels[studio]} />
      <StudioStatus studio={studio} setStudioArmed={setStudioArmed} channelsExist={channelsExist} />
      {studioArmed &&     
        <StudioBody
          studio={studio}
          setChannelsExist={setChannelsExist}
          setIsStudioEmpty={setIsStudioEmpty}
          setIsStudioRunning={setIsStudioRunning}
        />
      }
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 8,
          flexDirection: "row",
        }}
      >
        <Button
          sx={{ my: 0.2, minWidth: "100px"}}
          variant="contained"
          color="warning"
          size="small"
          disabled={!isStudioRunning}
          onClick={()=> {
            setIsModalOpen(true);
            setIsStop(true);
            console.log('global stop');
            }}
        >
          STOP
        </Button>
        <Button
          sx={{ my: 0.2, minWidth: "100px"}}
          variant="contained"
          color="error"
          size="small"
          disabled={isStudioEmpty || isStudioRunning}
          onClick={()=> {
            setIsModalOpen(true);
            setIsStop(false);
            console.log('global delete');
            }}
        >
          DELETE
        </Button>
      </Box>
      
    </>
  );
};

export default StudioPage;
