import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { linkMap } from "../../utils/constants";

export const HLSController = ({ studio, data }) => {
  const [status, setStatus] = useState(data?.status || null);
  useEffect(() => {
    setStatus(data?.status || null)
  }, [data])
  const [, createChannelRequest] = useFetchData();
  const [, startChannelRequest] = useFetchData();
  const [, stopChannelRequest] = useFetchData();
  const [, deleteChannelRequest] = useFetchData();
  const createHandler = (data) => {
    const dataToSubmit = {
      studio,
      destination: 'livego',
      sendHLS: true,
    }
    setStatus('CREATING')
    createChannelRequest(
      dataToSubmit,
      "/create-channel"
    );
  }
  const startHandler = () => {
    const dataToSubmit = {
      studio,
      destination: 'livego',
      sendHLS: true,
    }
    setStatus('STARTING')
    startChannelRequest(dataToSubmit, '/start-channel')
  }
  const stopHandler = () => {
    const dataToSubmit = {
      studio,
      destination: 'livego',
      sendHLS: true,
    }
    setStatus('STOPPING')
    stopChannelRequest(dataToSubmit,'/stop-channel')
  }
  const deleteHandler = () => {
    const dataToSubmit = {
      studio,
      destination: 'livego',
      sendHLS: true,
    }
    setStatus('DELETING');
    deleteChannelRequest(dataToSubmit,'/delete-channel');
  }
  // STUFFMFLKENFOENFJOSEFJESLFNSEFNLES
  // const clearFields = () => {
  //   setPriUrl(null);
  //   setPriStreamKey(null);
  //   setBackupUrl(null);
  //   setBackupStreamKey(null);
  // }
  const handleCreate = () => {
    createHandler();
  };

  return (
    <form>
      <Box
        key='livego'
        sx={{
          display: "flex",
          ml: 1,
          mb: 4,
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{ fontWeight: 400 }}
          align="left"
          variant="h4"
          component="h4"
        >
          {'Live<GO>'}
        </Typography>
        <Typography
          sx={{ fontWeight: 200 }}
          align="left"
          variant="overline"
        >
          {`Live<GO> has static destinations`}
        </Typography>
        <Box
          sx={{height: status === "RUNNING" ? "271px" : "271px", display: "flex", alignItems: "center", justifyContent: "center"}}
        >
        <Typography
          sx={{ fontWeight: 200 }}
          align="center"
          variant="h5"
        >
        {status === "RUNNING" && <Link to={linkMap[studio]} target="_blank" style={{ color: '#FFF' }}>link</Link>}
        </Typography>
        </Box>
        <Typography
          sx={{ fontWeight: 200 }}
          align="left"
          variant="overline"
        >
          {status ? status : `Channel Controls`}
        </Typography>
        <Button
          sx={{
            my: 0.2,
          }}
          variant="contained"
          color="info"
          size="small"
          disabled={!!data || status === 'CREATING'}
          onClick={handleCreate}
        >Create</Button>
        <Button
          sx={{
            my: 0.2, minWidth: "240px" 
          }}
          variant="contained"
          color="success"
          size="small"
          disabled={status !== 'IDLE'}
          onClick={startHandler}
        >Start</Button>
        <Button
          sx={{
            my: 0.2,
          }}
          variant="contained"
          color="warning"
          size="small"
          disabled={status !== 'RUNNING' || status === null}
          onClick={stopHandler}
        >Stop</Button>
        <Button
          sx={{
            my: 0.2,
          }}
          variant="contained"
          color="error"
          size="small"
          disabled={status !== 'IDLE'}
          onClick={deleteHandler}
        >Delete</Button>
      </Box>
    </form>
  )
};

export default HLSController;