import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";

const CustomDropdown = ({ value, setValue, label, id, options, isRequired = false, isDisabled = false}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <FormControl fullWidth required={isRequired} disabled={isDisabled}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        id={id}
        value={value}
        label={label}
        onChange={handleChange}
      >
        {options.map(option =>  <MenuItem key={option} value={option}>{option}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
