import { Box, Modal, Typography } from '@mui/material';

const ConfirmationModal = ({ handleModalClose, modalBody, modalHeader, isModalOpen }) => {
  return(
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Box
        maxWidth={600}
        sx={{
          p: 10,
          backgroundColor: "#111111",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1, mt: -5, mb: 5 }}
        >
          {modalHeader}
        </Typography>
        <Typography>{modalBody}</Typography>
      </Box>
    </Modal>
  )
};

export default ConfirmationModal;