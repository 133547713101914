import { Grid } from "@mui/material";
import SquareCard from "../components/shared/SquareCard";

export const studioLabels = [
  {source: "EMEA", label: "System 1"},
  {source: "APAC", label: "System 2"},
  {source: "Americas", label: "System 3"}
]

export const PropogatorHome = ({setStudio}) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="space-evenly"
    spacing={10}
  >
    {studioLabels.map((studioOb) => (
      <Grid item key={studioOb.source}>
        <SquareCard name={studioOb.label} clickHandler={() => setStudio(studioOb.source)} />
      </Grid>
    ))}
  </Grid>
);

export default PropogatorHome;
