import { useState } from 'react';
import { Modal, Button, Box, TextField, Typography } from "@mui/material";

const RefTestModal = ({ modalState, setModal, checkCode, modalText, setModalText }) => {
  const [accessCode, setAccessCode] = useState('')
  const handleModalClose = () => {
    setModal(false);
    setModalText(null)
  }
  const handleKey = (event) => {
    if (event.key === 'Enter') {
      checkCode(accessCode)
    }
  }
  return (
    <Modal
      open={modalState}
      onClose={handleModalClose}
    >
      <Box
      sx={{ 
        p: 10,
        backgroundColor: '#111111',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      >
        {modalText && <Typography
          sx={{ fontWeight: 400, mb:4 }}
          variant="h4"
          component="h4"
        >
          {modalText}
        </Typography>}
        <TextField
          label="BGET Number"
          onChange={(e) => setAccessCode(e.target.value)}
          required
          size="small"
          value={accessCode}
          onKeyUp={handleKey}
        />
        <Button
          sx={{ml: 2}}
          variant="contained"
          onClick={() => checkCode(accessCode)}
        >
          submit code
        </Button>
      </Box>
    </Modal>
  ) 
}

export default RefTestModal;
