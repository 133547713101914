import { useState, useCallback } from "react";
import { API } from "aws-amplify";

export const useFetchData = () => {
  const [res, setRes] = useState({ data: null, error: null, isLoading: false });
  // const [error, setError]
  // You POST method here

  const callAPI = useCallback((body, path) => {
    const payload = {
      body,
    };
    setRes((prevState) => ({ ...prevState, isLoading: true }));
    API.post("default", path, payload)
      .then((res) => {
        setRes({ data: res, isLoading: false, error: null });
      })
      .catch((error) => {
        setRes({ data: null, isLoading: false, error });
      });
  }, []);
  return [res, callAPI];
};
