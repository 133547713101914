import { FormControl, TextField } from "@mui/material";

const ShowTitleInput = ({ setName, setNameValue }) => {
  return (
    <FormControl fullWidth>
      <TextField
        label="Event Name (minimum of 3 characters)"
        onChange={(e) => {
          setName(e.target.value);
        }}
        required
        value={setNameValue}
      />
    </FormControl>
  );
};

export default ShowTitleInput;
