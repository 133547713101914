export const studioLabels = {
  EMEA: "System 1",
  APAC: "System 2",
  Americas: "System 3"
};

export const eventTechnicians = [
  'Amos Tse',
  'Brad Crosby',
  'Christopher Morrissey',
  'Dan Lock',
  'Jordan Moll',
  'Joshua Roberts',
  'Julian Gomez',
  'Nazy Zakaria',
  'Oradee Imvised',
  'Rashid Ramkissoon',
  'Richard Daley',
  'Ryan Meyer',
  'William White',
]
