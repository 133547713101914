export const sources = ["youtube", "twitter", "facebook", "Twitch", "Intrado"];
export const sourceLabels = [
  { source: "notified", label: "Notified" },
  { source: "bizzabo", label: "Bizzabo" },
  { source: "hopin", label: "Hopin" },
  { source: "marketpartner", label: "Market Partner" },
];
export const extendedSourceLabels = [
  { source: "facebook", label: "Facebook" },
  { source: "twitter", label: "X" },
  { source: "youtube", label: "YouTube" },
  { source: "linkedin", label: "LinkedIn" },
  { source: "custom1", label: "Custom 1" },
  { source: "custom2", label: "Custom 2" },
];
export const groupOptions = [1,2,3,4];
export const preRecordOptions = [0.5, 1, 2]

export const engagementTypeOptions = ['Event', 'Non-Event', 'Test'];

export const initialSchedFormState = {
  eventTech: '',
  backupEventTech: '',
  engagementType: '',
  invoicingContacts: '',
  laborRequest: '',
  zoomWeb: false,
  breakouts: false,
  addSpeakerRooms: false,
  graphSupport: false,
  addStreaming: false,
  multiday: false,
  specialRequests: false,
  howsItViewed: '',
  fieldUnitSerialNums: '',
  isMultiCam: false,
  requiredUnits: '',
  liveU: false,
  numSpeakers: 0,
  group: 1,
  name: '',
  duration: '',
  preRecord: '',
  startTime: '',
  region: '',
  system: 'System_1',
  refNum: '',
  sdskNum: '',
  hours: '',
  mins: '',
  checked: true,
  date: new Date(),
};

export const regionOptions = [
  'APAC',
  'Americas',
  'EMEA'
];

export const systemOptions = [
  'System_1',
  'System_2',
  'System_3'
];

export const etList = [
  'Amos Tse',
  'Brad Crosby',
  'Christopher Morrissey',
  'Dan Lock',
  'Jordan Moll',
  'Joshua Roberts',
  'Julian Gomez',
  'Nazy Zakaria',
  'Oradee Imvised',
  'Rashid Ramkissoon',
  'Richard Daley',
  'Ryan Meyer',
  'William White',
];
