import { useState } from "react";
import { Container } from "@mui/material";
import AppHeader from "./components/shared/AppHeader";
import AppHome from "./pages/AppHome";
import { SchedulatorContext } from "./utils/Context";
import { BookingMode } from "./utils/Context";
import { UpdateCalContext } from "./utils/Context";
import Bookings from "./pages/Bookings";
import PropagatorHome from './pages/PropagatorHome';
import { initialSchedFormState } from "./utils/formHelpers";

const choosePropagatorSchedulator = ({app}) => {
  if (app === "Schedulator") {
    return <Bookings />;
  }
  if (app === "Propagator") {
    return <PropagatorHome />;
  }
  return null;
}

function App({ signOut }) {
  const [app, setApp] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [shouldUpdateCal, setShouldUpdateCal] = useState(false)
  const [bookingOb, setBookingOb] = useState(initialSchedFormState)



  return (
    <SchedulatorContext.Provider value={{bookingOb, setBookingOb}}>
      <BookingMode.Provider value={{isEdit, setIsEdit}}>
        <UpdateCalContext.Provider value={{shouldUpdateCal, setShouldUpdateCal}}>
          <AppHeader signOut={signOut} showHome={app} goHome={()=>setApp(null)} />
          <Container maxWidth="100%" sx={{ mt: 10 }}>
            <main>
              {app===null && <AppHome setApp={setApp} />}
              {choosePropagatorSchedulator({ app })}
            </main>
          </Container>
        </UpdateCalContext.Provider>
      </BookingMode.Provider>
    </SchedulatorContext.Provider>
  );
}

export default App;
